



































// --- Vue & Template imports ---
import { Component, Vue } from 'vue-property-decorator';

// --- Models ---
import { AppBarItem } from '@/models/App.model';

@Component
export default class PageAppBar extends Vue {

  items: AppBarItem[] = [];

  beforeMount() {
    this.updateAppBar(); 
    this.$root.$on('update:appBar', this.updateAppBar);
  }

  updateAppBar() {
    this.items = [
      {
        text: 'Logout',
        to: '/logout',
      },
    ];

    // if this screen is the Awaiting Approval page then we need to add the Edit button
    if (this.$router.currentRoute.name === 'Awaiting Approval') {
      this.items.unshift({
        text: 'Edit',
        to: '/edit',
      });
    } 
  }

}
